<template>
  <form @submit.prevent="update">
    <modal-card :title="`Update task subject`" :processing="processing">
      <loading v-if="loading" />
      <b-field v-else label="Subject">
        <b-field>
          <b-input
            ref="subject"
            v-model="form.subject"
            type="text"
            expanded
            placeholder="Enter a subject for this task"
          />
        </b-field>
      </b-field>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="processing"
        type="submit"
        class="button is-success"
      >
        Submit
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "UpdateTaskSubjectModal",
  props: {
    taskId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      form: {
        subject: ""
      }
    };
  },
  computed: {
    task() {
      return this.$store.getters["tasks/task"](this.taskId) || {};
    }
  },
  created() {
    this.$store
      .dispatch("tasks/observeTask", { taskId: this.taskId })
      .finally(() => {
        this.loading = false;
        this.form.subject = this.task.subject;
        this.$nextTick(() => {
          this.$refs.subject.$el.querySelector("input").focus();
        });
      });
  },
  beforeDestroy() {
    this.$store.dispatch("tasks/binTask", { taskId: this.taskId });
  },
  methods: {
    update() {
      this.processing = true;
      this.$store
        .dispatch("tasks/updateSubject", {
          taskId: this.taskId,
          subject: this.$_.trim(this.form.subject)
        })
        .then(() => {
          this.$emit("close");
          this.$toast.open({
            message: "Subject updated"
          });
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
